import React, { useLayoutEffect, useMemo } from 'react';
import { ErrorBoundary } from '@mentimeter/errors/ErrorBoundary';
import { VotingContext } from '@mentimeter/question-modules-contexts';
import { MissingQuestionModuleError } from '@mentimeter/question-modules-shared';
import type { Question } from '@mentimeter/http-clients';
import { Box, ScreenReaderOnly } from '@mentimeter/ragnar-ui';
import { importSlideTypeDynamically } from '../../slide-types/importSlideTypeDynamically';
import { createSDK } from './sdk-setup';

interface Props {
  question: Question;
  children: (
    Content: React.ComponentType,
    Interactive: React.ComponentType,
  ) => React.ReactNode;
}

/**
 * Returns true if any element in the  bottom panel have focus.
 * Bottom panel hosts CFA and QFA, and having focus there indicates that the
 * user is currently interacting with these features and should not be forced away.
 */
const isFocusWithinBottomPanel = () => {
  return document.querySelector('.bottom-panel :focus') !== null;
};

export const Module = ({ question, children }: Props) => {
  const Content = useMemo(
    () => importSlideTypeDynamically(question.module_id, 'content'),
    [question.module_id],
  );
  const Interactive = useMemo(
    () => importSlideTypeDynamically(question.module_id, 'interactive'),
    [question.module_id],
  );

  const sdk = useMemo(() => createSDK(question), [question]);

  useLayoutEffect(() => {
    if (isFocusWithinBottomPanel()) return;

    // A11y: Focus on main when slide is changed
    document.querySelector('main')?.focus();
  }, [question.public_key]);

  const questionTitle =
    question.slide_type === 'free-text'
      ? question.question_description
      : question.question;

  return (
    <ErrorBoundary feature="slides" fallback={<MissingQuestionModuleError />}>
      <VotingContext.Provider value={sdk}>
        <Box as="main" tabIndex={-1} width="100%">
          <ScreenReaderOnly aria-live="assertive" aria-atomic>
            Slide has changed: {questionTitle}
          </ScreenReaderOnly>
          {children(Content, Interactive)}
        </Box>
      </VotingContext.Provider>
    </ErrorBoundary>
  );
};
