import * as React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { Text, type TextT } from '../Text';
import { variants } from './typography.variants';

export const Heading = ({ className, ...rest }: TextT) => {
  const classes = variants({ variant: 'heading' });

  return <Text className={cn(classes, className)} {...rest} />;
};

export const SubHeading = ({ className, ...rest }: TextT) => {
  const classes = variants({ variant: 'subheading' });

  return <Text className={cn(classes, className)} {...rest} />;
};

export const P = ({ className, ...rest }: TextT) => {
  const classes = variants({ variant: 'paragraph' });

  return <Text as="p" className={cn(classes, className)} {...rest} />;
};

export const Strong = ({ className, ...rest }: TextT) => {
  const classes = variants({ variant: 'strong' });

  return <Text className={cn(classes, className)} {...rest} />;
};

export const Meta = ({ className, ...rest }: TextT) => {
  const classes = variants({ variant: 'meta' });

  return <Text className={cn(classes, className)} {...rest} />;
};
