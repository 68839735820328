import * as React from 'react';
import { useAppDispatch } from '../../redux-hooks';
import { onAudiencePaceSlideChange } from '../../reducers/votingSlice';
import { AudiencePaceQuestion } from './AudiencePaceQuestion';
import { useAudiencePaceVoteParams } from './navigation/useAudiencePaceVoteParams';

export const AudiencePace = () => {
  const { voteKey, questionIndex } = useAudiencePaceVoteParams();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(onAudiencePaceSlideChange());
  }, [dispatch, questionIndex]);

  return (
    <AudiencePaceQuestion voteKey={voteKey} questionIndex={questionIndex} />
  );
};
