import { MentiError } from '@mentimeter/errors/edge';
import type Ably from 'ably';

export const fetchAblyRestHistoryApi = async <T>(
  channelName: string | null,
  client: Ably.Rest,
) => {
  if (channelName === null) {
    throw new MentiError('channelName is null', {
      feature: 'realtime',
    });
  }

  const restChannel = client.channels.get(channelName);

  const history = await restChannel.history({ limit: 1 });
  return history.items[0] as T | undefined;
};
