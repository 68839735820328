import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { CommentPill } from '@mentimeter/voting-ui';
import { useTransition, animated } from '@react-spring/web';
import { useTranslate } from '../localization/Translate';

interface SuggestionT {
  selectSuggestion: (arg0: string) => void;
}
export const Suggestions = ({ selectSuggestion }: SuggestionT) => {
  const translate = useTranslate();

  const DEFAULT_SUGGESTIONS = React.useMemo(
    () => [
      { text: `😍` },
      { text: `${translate('cfa.suggestion_amazing')} 🤩` },
      { text: 'Woohoo! 🎉' },
      { text: '🔥🔥🔥' },
      { text: `${translate('cfa.suggestion_mind_blown')} 🤯` },
      { text: '🥳' },
    ],
    [translate],
  );

  const transitions = useTransition(DEFAULT_SUGGESTIONS, {
    delay: 200,
    config: {
      duration: 650,
    },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    trail: 100,
  });

  return (
    <Box flexWrap="wrap" width="100%" flexDirection="row" gap="space4">
      {transitions((styles, item) => {
        return (
          <animated.div key={item.text} style={styles}>
            <CommentPill label={item.text} onClick={selectSuggestion} />
          </animated.div>
        );
      })}
    </Box>
  );
};
