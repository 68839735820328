import * as React from 'react';
import { Box, ModalGeneric } from '@mentimeter/ragnar-ui';

interface PropsT {
  show: boolean;
  onDismiss?: (() => void) | undefined;
  children?: React.ReactNode;
}

const Modal = ({ show, onDismiss, children }: PropsT) => (
  <ModalGeneric center show={show} onDismiss={onDismiss}>
    <Box
      borderRadius={2}
      overflow="hidden"
      bg="bg"
      width="100%"
      p={4}
      maxWidth="90vw"
    >
      {children}
    </Box>
  </ModalGeneric>
);

export default Modal;
