import * as React from 'react';
import twemoji from 'twemoji';
import { detectEmojis } from './utils';

export interface PropsT {
  children: React.ReactNode;
}

const STYLE =
  'height:1em;width:1em;margin: 0 .05em 0 .1em;vertical-align:-0.15em;';

const EmojiParser = ({
  children,
}: {
  children: React.ReactNode | string | undefined;
}) => {
  const node = React.useRef<HTMLSpanElement>(null);
  React.useEffect(() => {
    if (!node.current) return;
    twemoji.parse(node.current, {
      base: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/13.0.1/', // maxCDN broke, hotfix
      attributes: () => ({ style: STYLE }),
    });
  });
  const content =
    typeof children === 'string' ? detectEmojis(children) : children;
  return <span ref={node}>{content}</span>;
};

export default React.memo(EmojiParser);
