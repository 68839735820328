import { useParams } from 'next/navigation';

const parseQuestionIndex = (questionIndex: string | undefined) => {
  if (!questionIndex) return 0;
  return parseInt(questionIndex, 10) || 0;
};

export const useAudiencePaceVoteParams = () => {
  const [voteKey, questionIndex] = useParams()?.voteParams as string[];
  return {
    voteKey: voteKey!,
    questionIndex: parseQuestionIndex(questionIndex),
  };
};
