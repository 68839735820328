import * as React from 'react';
import { useTransition, animated, config } from 'react-spring';

interface PropsT {
  children: React.ReactNode;
  show?: boolean;
  delay?: number;
  immediate?: boolean;
}

const Fade = ({
  show = false,
  delay = 0,
  immediate = false,
  children,
}: PropsT) => {
  const transition = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.slow,
    delay,
    immediate,
  });

  return (
    <>
      {transition((styles, show) =>
        show ? <animated.div style={styles}>{children}</animated.div> : null,
      )}
    </>
  );
};

export default Fade;
