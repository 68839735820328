import { useCallback, useEffect } from 'react';
import type { ParticipantIdentity } from '@mentimeter/question-modules-types';
import { isAxiosError, voting } from '@mentimeter/http-clients';
import { captureException } from '@sentry/nextjs';
import { MentiError } from '@mentimeter/errors/edge';
import { getRegionByVoteKey } from '@mentimeter/region';
import { usePresentationState } from '../../presentation-state';
import { useAppSelector } from '../../redux-hooks';
import { useTranslate } from '../localization/Translate';
import { useTrack } from '../../utils/tracker';

export type SubmitResult =
  | { status: 'success' }
  | { status: 'error'; message: string };

export function useIdentity(): {
  identity: ParticipantIdentity;
  submitName: (name: string) => Promise<SubmitResult>;
  resetIdentity: () => void;
} {
  const translate = useTranslate();
  const trackVoter = useTrack();

  const participationIdentityMode = useAppSelector(
    (state) => state.series.participation_identity_mode,
  );
  const voteKey = useAppSelector((state) => state.series.vote_key);

  const { participantIdentity, setParticipantIdentity } =
    usePresentationState();

  const getParticipantIdentity = useCallback(async () => {
    if (participationIdentityMode !== 'identified') {
      setParticipantIdentity({ status: 'series_is_anonymous' });
      return;
    }

    setParticipantIdentity({ status: 'loading' });

    try {
      const region = getRegionByVoteKey(voteKey);
      const response = await voting({ region }).audience.identity.get(voteKey);
      const name = response.data.display_name;
      setParticipantIdentity({ status: 'is_identified', name });
    } catch (e: unknown) {
      if (isAxiosError(e) && e.response?.status === 404) {
        setParticipantIdentity({ status: 'needs_identification' });
      } else {
        captureException(
          new MentiError('Audience identity get endpoint failed', {
            cause: e,
            feature: 'identified-responses',
          }),
        );
        setParticipantIdentity({ status: 'error' });
      }
    }
  }, [participationIdentityMode, setParticipantIdentity, voteKey]);

  useEffect(() => {
    getParticipantIdentity();
  }, [getParticipantIdentity]);

  const submitName = useCallback(
    async (name: string): Promise<SubmitResult> => {
      try {
        const region = getRegionByVoteKey(voteKey);
        const response = await voting({ region }).audience.identity.post(
          voteKey,
          {
            displayName: name,
          },
        );

        setParticipantIdentity({
          status: 'is_identified',
          name: response.data.display_name,
        });

        trackVoter(
          {
            event: 'Identified responses form submitted',
          },
          region,
        );

        return { status: 'success' };
      } catch (e: unknown) {
        if (isAxiosError(e) && e.response?.status === 400) {
          return {
            status: 'error',
            message: translate('identified_responses.name_not_valid'),
          };
        }
        if (
          isAxiosError<{ code: string }>(e) &&
          e.response?.status === 422 &&
          e.response?.data?.code === 'name_contains_profanity'
        ) {
          return {
            status: 'error',
            message: translate('identified_responses.name_contains_profanity'),
          };
        }
        if (
          isAxiosError<{ code: string; display_name: string }>(e) &&
          e.response?.status === 409 &&
          e.response.data.code === 'identity_already_exists'
        ) {
          const name = e.response.data.display_name;
          setParticipantIdentity({
            status: 'needs_to_see_welcome_back_screen',
            name,
            onContinue: () =>
              setParticipantIdentity({ status: 'is_identified', name }),
          });
          return {
            status: 'success',
          };
        }
        captureException(
          new MentiError('Audience identity post endpoint failed', {
            cause: e,
            feature: 'identified-responses',
          }),
        );
        throw e;
      }
    },
    [voteKey, setParticipantIdentity, translate, trackVoter],
  );

  const resetIdentity = useCallback(() => {
    if (participationIdentityMode === 'identified') {
      getParticipantIdentity();
      return;
    }

    setParticipantIdentity({ status: 'series_is_anonymous' });
  }, [
    participationIdentityMode,
    setParticipantIdentity,
    getParticipantIdentity,
  ]);

  return {
    identity: participantIdentity,
    submitName,
    resetIdentity,
  };
}
