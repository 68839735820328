import * as React from 'react';
import axios from 'axios';

export { default as RemarkMath } from 'remark-math';

export const WRAPPER_STYLES = { margin: '2% 0px' };
interface SvgPropsT {
  math: string;
  renderApiUrl: string;
}

export const MathSvg = ({ math, renderApiUrl }: SvgPropsT) => {
  const [svg, setSvg] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  /**
   * [React 19 RC]
   * This is a throw-away state to ensure the components gets properly
   * re-rendered after the fetch. It's possible that the RC has some
   * changes to how tests are re-rendered that have yet to be addressed
   * by jest.
   */
  const [, setIsPending] = React.useState(false);

  React.useEffect(() => {
    const value = math.replace('block', '');
    // encoding necessary so i.e. '+' is not interpreted as space
    const encodedValue = encodeURIComponent(value);
    const URL = `${renderApiUrl}/math?latex=`;
    setIsPending(true);
    axios
      .get(`${URL}${encodedValue}`)
      .then((res) => {
        setErrorMessage('');
        setSvg(res.data);
      })
      .catch((err) => {
        // Error handling
        setSvg('');
        if (err.response?.status === 400 && err.response?.data) {
          setErrorMessage(err.response.data);
        } else {
          setErrorMessage('');
        }
      })
      .finally(() => {
        setIsPending(false);
      });
  }, [math, renderApiUrl]);

  const isInline = Boolean(math) && math.indexOf('block') <= -1;
  const Element = isInline ? 'span' : 'div';

  if (errorMessage) {
    return <Element>{errorMessage}</Element>;
  }

  // Only use backend response dangerously and
  // fallback to inserting math string
  return svg ? (
    <Element
      className="theme-text-color--color"
      style={WRAPPER_STYLES}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  ) : (
    <Element>{math}</Element>
  );
};
