import React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import type { Responsive } from '@mentimeter/ragnar-styled';
import type { ColorsEnum } from '@mentimeter/ragnar-colors';
import { Text } from '../../Text';
import { variants, type BadgeTextVariantProps } from './BadgeText.variants';

export interface BadgeTextProps extends BadgeTextVariantProps {
  children: string;
  className?: string;
  color?: Responsive<ColorsEnum> | undefined;
}

export function BadgeText({
  size,
  textTransform,
  children,
  className,
  ...rest
}: BadgeTextProps) {
  const classes = variants({ size, textTransform });

  return (
    <Text className={cn(classes, className)} {...rest}>
      {children}
    </Text>
  );
}
