import { voting } from '@mentimeter/http-clients';

export const sendResultsMail = ({
  emailAddress,
  voteKey,
  questionId,
  notes,
  resultType,
}: {
  emailAddress: string;
  voteKey: string;
  questionId?: string;
  notes?: string;
  resultType?: string;
}) => {
  return voting().results.email(voteKey, {
    email: emailAddress,
    // @ts-expect-error-auto TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    question_id: questionId,
    // @ts-expect-error-auto TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    notes,
    // @ts-expect-error-auto TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    result_type: resultType,
  });
};
