'use client';
import { DeviceContext, useMatch } from '@mentimeter/ragnar-device';
import React from 'react';
import { useAppSelector } from '../../redux-hooks';

const DESKTOP_EXPERIENCE_DISABLED =
  process.env.NEXT_PUBLIC_DISABLE_DESKTOP_EXPERIENCE_FEATURE === 'true';

export const useShouldRequestDesktopExperience = () => {
  const paceMode = useAppSelector((state) => state.series.pace.mode);
  const resultsSharing = useAppSelector(
    (state) => state.series.results_sharing,
  );
  const { initialized } = React.useContext(DeviceContext);
  const isDesktop = useMatch({ greaterThan: 2 });

  return {
    initialized,
    shouldRequestDesktopExperience:
      isDesktop &&
      paceMode === 'presenter' &&
      resultsSharing === 'on' &&
      !DESKTOP_EXPERIENCE_DISABLED,
  };
};
