import { createComponent } from '@mentimeter/ragnar-react';
import type { IRenderer } from 'fela';

const float = () => ({
  '0%': {
    transform: 'translateY(-5px)',
  },
  '50%': {
    transform: `translateY(5px)`,
  },
  '100%': {
    transform: 'translateY(-5px)',
  },
});

const floatRule = (
  { duration = 2000, delay = 0 }: { duration?: number; delay?: number },
  renderer: IRenderer,
) => ({
  animation: `${renderer.renderKeyframe(
    float,
    {},
  )} ${duration}ms ${delay}ms ease-in-out infinite`,
});

const Float = createComponent(floatRule, 'div');

export default Float;
