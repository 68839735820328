import React from 'react';
import { Box, Text } from '@mentimeter/ragnar-ui';
import { HourGlass } from '@mentimeter/ragnar-visuals';
import { Fade, Float } from '@mentimeter/ragnar-motion';

const PresentationInProgress = ({
  pictogramColor,
}: {
  pictogramColor: string | undefined;
}) => {
  return (
    <Box width="100%" my="space14" alignItems="center">
      <Fade show>
        <Float duration={2000}>
          <Box height="112px">
            <HourGlass userColor={pictogramColor} />
          </Box>
        </Float>
      </Fade>
      <Text mt="space6" mb="space2">
        Presentation in progress...
      </Text>
    </Box>
  );
};

export default PresentationInProgress;
