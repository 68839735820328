import React from 'react';
import type Ably from 'ably';
import { MentiError, captureException } from '@mentimeter/errors/edge';
import type {
  PresentationStateChannel,
  PresentationStateEvents,
  SeriesPrivateChannel,
} from './channel-enums';
import { channelGenerator } from './channel-generator';
import type { PresentationStatePayload } from './types';
import type { Payload } from './utility-types';
import { fetchAblyRestHistoryApi } from './fetchAblyRestHistoryApi';
import { isAblyError } from './utils';

interface UseHistoryParams<T> {
  client: Ably.Rest;
  channel: PresentationStateChannel | SeriesPrivateChannel;
  callback: (msg: Payload<any, T> | undefined) => void;
}

// --------------------------------------------
// FUNCTION OVERLOADS FOR BETTER MESSAGE TYPING
// --------------------------------------------

/**
 * A presentation state update
 */
export function useHistory<T>({
  channel,
  callback,
}: UseHistoryParams<T> & {
  channel: PresentationStateChannel;
  callback: (
    msg:
      | Payload<
          PresentationStateEvents.PRESENTATION_STATE_SYNC_STATE,
          PresentationStatePayload
        >
      | undefined,
  ) => void;
}): void;

// -------------------------
// END OF FUNCTION OVERLOADS
// -------------------------

export function useHistory<T>({
  client,
  channel,
  callback,
}: UseHistoryParams<T>) {
  const channelName = channelGenerator(channel);
  const [status, setStatus] = React.useState<'loading' | 'success' | 'error'>();

  React.useEffect(() => {
    setStatus('loading');
    fetchAblyRestHistoryApi<Payload<any, T>>(channelName, client)
      .then(callback)
      .then(() => {
        setStatus('success');
      })
      .catch((error) => {
        setStatus('error');
        if (isAblyError(error)) {
          if (shouldLogError(error)) {
            captureException(
              new MentiError(
                `Ably History API fetch failed with code: '${error.code}', status: ${error.statusCode}.`,
                { feature: 'realtime', cause: error },
              ),
            );
          }
          return;
        }

        captureException(
          new MentiError(`Ably History API fetch failed for unknown cause.`, {
            feature: 'realtime',
            cause: error,
          }),
        );
      });
  }, [callback, channelName, client]);

  return status;
}

const ABLY_ERROR_CODES_TO_IGNORE = [
  50003, // Timeout
];

const HTTP_STATUS_CODES_TO_IGNORE = [
  408, // Timeout
];

const shouldLogError = (error: Ably.ErrorInfo) => {
  if (ABLY_ERROR_CODES_TO_IGNORE.includes(error.code)) return false;
  if (HTTP_STATUS_CODES_TO_IGNORE.includes(error.statusCode)) return false;
  if (
    error.statusCode === 400 &&
    error.message.startsWith('XHR error occurred')
  ) {
    // These are network level errors and timeouts
    return false;
  }
  return true;
};
