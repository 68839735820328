import React from 'react';
import type { DSC } from '@mentimeter/ragnar-dsc';
import {
  Box,
  inputRule,
  Text,
  resolveInputHoverStyle,
  resolveInputFocusStyle,
  type InputRuleStatus,
} from '@mentimeter/ragnar-ui';

export const PrefixWrapper = ({
  disabled,
  prefix,
  children,
  status,
}: {
  disabled?: boolean | undefined;
  prefix?: string | undefined;
  children: React.ReactNode;
  status: InputRuleStatus;
}) => (
  <Box
    className="testDiv"
    flexDirection="row"
    flexWrap="wrap"
    alignItems="center"
    overflow="auto"
    tabIndex={!disabled ? 0 : undefined}
    display="inline-flex"
    minHeight="40px"
    gap="4px"
    pl="8px"
    height="auto"
    extend={({ theme }: { theme: DSC }) => ({
      ...inputRule({ theme, status }),
      ':hover': {
        ...resolveInputHoverStyle(status, theme),
        '> div .inputClass': {
          ...resolveInputHoverStyle(status, theme),
          outline: 0,
          borderWidth: 0,
          boxShadow: 'none',
        },
      },
      ':focus-within': {
        ...resolveInputFocusStyle(status, theme),
      },
      '> div input': {
        backgroundColor: 'transparent',
        margin: '1px',
        borderWidth: 0,
        boxShadow: 'none',
      },
      '> div input:focus': {
        backgroundColor: 'transparent',
        outline: 0,
        borderWidth: 0,
        boxShadow: 'none',
      },
      '> div input:focus:visible': {
        backgroundColor: 'transparent',
        outline: 'none',
        borderWidth: 0,
        boxShadow: 'none',
      },
      '> div input:hover:enabled': {
        backgroundColor: 'transparent',
        outline: 0,
        borderWidth: 0,
        boxShadow: 'none',
      },
    })}
  >
    <Box flexDirection="row" flex="1 1 auto" alignItems="center">
      <Text fontSize="87.5" color="inherit" lineHeight="inherit">
        {prefix}
      </Text>
      {children}
    </Box>
  </Box>
);
