import * as React from 'react';
import type { FormikHelpers, FormikErrors } from 'formik';
import { Formik } from 'formik';
import { Form as WrappingForm, type BoxT } from '@mentimeter/ragnar-ui';

export type FormHelpers = FormikHelpers<Values>;

export type Values = any;
export type OnSubmitT = (
  values: Values,
  formikActions: FormikHelpers<Values>,
) => void;

export interface FormT extends Omit<BoxT, 'onReset' | 'onSubmit'> {
  initialValues: Values;
  validationSchema?: any;
  enableReinitialize?: boolean;
  validateOnMount?: boolean;
  initialStatus?: any;
  onReset?: (values: Values, formikActions: FormikHelpers<Values>) => void;
  validate?: (values: Values) => FormikErrors<Values> | Promise<any>;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
  children: React.ReactNode;
  onSubmit: OnSubmitT;
}

export const Form: React.FC<FormT> = ({
  children,
  initialValues,
  validationSchema,
  enableReinitialize = false,
  validateOnMount = false,
  initialStatus,
  onReset = () => {},
  validate = () => {},
  validateOnBlur = true,
  validateOnChange = true,
  width,
  alignItems,
  maxWidth,
  mx,
  onSubmit,
}) => {
  const handleSubmit = (
    values: Values,
    formikActions: FormikHelpers<Values>,
  ) => {
    return onSubmit(values, formikActions);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize={enableReinitialize}
      validateOnMount={validateOnMount}
      initialStatus={initialStatus}
      onReset={onReset}
      validate={validate}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
    >
      {(props) => {
        const { handleSubmit } = props;
        return (
          <WrappingForm
            method="post"
            onSubmit={handleSubmit}
            alignItems={alignItems}
            width={width}
            maxWidth={maxWidth}
            mx={mx}
          >
            {children}
          </WrappingForm>
        );
      }}
    </Formik>
  );
};
