import React, { useCallback } from 'react';
import { Box, Text } from '@mentimeter/ragnar-ui';
import type { Question } from '@mentimeter/http-clients';
import { QuestionImage } from '@mentimeter/question-modules-shared';
import { useTrack } from '../../utils/tracker';
import { usePresentationState } from '../../presentation-state';
import { useTranslate } from '../localization/Translate';
import { FinishScreenExperimentContent } from '../finish-screen/FinishScreenExperiment';
import HasVotedOnQuestion from '../presenter-pace/HasVotedOnQuestion';
import WaitForActive from '../presenter-pace/WaitForActive';
import { CloseVotingNotification } from '../questions/CloseVotingNotification';
import { Module } from '../questions/Module';
import { Footer, NavigationButton } from '../../ui';
import { SlideInteractions } from '../questions/SlideInteractions';
import { useAppDispatch, useAppSelector } from '../../redux-hooks';
import { getDSCWithTheme } from '../../selectors';
import { Cfa } from '../cfa/Cfa';
import { Qfa } from '../qfa/Qfa';
import { ChangeSlideNotification } from '../presenter-pace/ChangeSlideNotification';
import { onFollowPresenter } from '../../reducers/votingSlice';
import { InteractionObserver } from '../InteractionObserver';
import { shouldSlideShowNavigation } from '../questions/shouldSlideShowNavigation';
import PresentationInProgress from './PresentationInProgress';

const Content = ({
  question,
  hasVotedOnCurrentQuestion,
  waitForNextSlide,
}: {
  question: Question;
  hasVotedOnCurrentQuestion: boolean;
  waitForNextSlide: boolean;
}) => {
  const theme = useAppSelector(getDSCWithTheme);
  const translate = useTranslate();
  const { setSlideHasBeenInteractedWith, participantIdentity } =
    usePresentationState();
  const handleSlideInteraction = useCallback(
    () => setSlideHasBeenInteractedWith(question.public_key),
    [question.public_key, setSlideHasBeenInteractedWith],
  );
  const isQuizSlide = ['quiz', 'quiz_open'].includes(question.type);
  const showParticipantImage =
    !isQuizSlide && question.layout?.type === 'default';
  const isStaticSlide = ['slide', 'qfa'].includes(question.type);

  if (hasVotedOnCurrentQuestion)
    return <HasVotedOnQuestion pictogramColor={theme.pictogramColor} />;
  if (waitForNextSlide)
    return <WaitForActive pictogramColor={theme.pictogramColor} />;
  if (isStaticSlide) {
    return (
      <>
        {showParticipantImage && (
          <QuestionImage src={question.image} translateFn={translate} mb={3} />
        )}

        <PresentationInProgress pictogramColor={theme.colors.disabledStrong} />
      </>
    );
  }

  return (
    <>
      {!question.active && <CloseVotingNotification />}

      {showParticipantImage && (
        <QuestionImage src={question.image} translateFn={translate} mb={3} />
      )}

      {question.question_description && (
        <Box width="100%" pb="space6">
          <Text color="text">{question.question_description}</Text>
        </Box>
      )}

      <InteractionObserver onInteraction={handleSlideInteraction}>
        <Module key={question.id} question={question}>
          {(_, Interactive) => <Interactive />}
        </Module>
      </InteractionObserver>
      {participantIdentity.status === 'is_identified' && (
        <Text color="textWeaker" mt="space6">
          {translate('identified_responses.responding_as', {
            name: participantIdentity.name,
          })}
        </Text>
      )}
    </>
  );
};

const EndScreen = () => (
  <Box width="100%" flex="1 0 auto" alignItems="center">
    <FinishScreenExperimentContent qfa={<Qfa isFinishScreen absolute />} />
  </Box>
);

export const VotingPanel = ({ question }: { question: Question }) => {
  const {
    hasVotedOnCurrentQuestion,
    waitForNextSlide,
    showEndScreen,
    goToSuccessPage,
    hasNextSlide,
  } = usePresentationState();
  const translate = useTranslate();
  const showFinishButton =
    !hasNextSlide && shouldSlideShowNavigation(question.type);
  const { isOutOfSync, followPresenter } = usePresentationState();

  const trackVoter = useTrack();
  const dispatch = useAppDispatch();

  if (showEndScreen) return <EndScreen />;

  return (
    <>
      <Box width="100%" flex="1 0 auto" alignItems="center">
        <Box width="100%" mt="space20" />
        {isOutOfSync && (
          <ChangeSlideNotification
            onClick={() => {
              trackVoter({
                event: 'Clicked go to slide',
                properties: { 'question type': question.type },
              });
              dispatch(onFollowPresenter());
              followPresenter();
            }}
          />
        )}
        <Content
          question={question}
          hasVotedOnCurrentQuestion={hasVotedOnCurrentQuestion}
          waitForNextSlide={waitForNextSlide}
        />

        {showFinishButton && (
          <NavigationButton onClick={goToSuccessPage}>
            {translate('buttons.finish')}
          </NavigationButton>
        )}
        <SlideInteractions
          question={question}
          cfa={<Cfa absolute activePublicKey={question.public_key} />}
          qfa={
            <Qfa
              absolute
              activePublicKey={question.public_key}
              moduleId={question.module_id}
            />
          }
        />
      </Box>
      <Box justifyContent="center" width="100%" overflow="hidden">
        <Footer />
      </Box>
    </>
  );
};
