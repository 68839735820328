import { useLayoutEffect } from './isomorphicLayoutEffect';

/**
 * Locks the scroll of the body element when enabled. Useful for elements
 * that show in front of other content, like menus and modals.
 * @param enabled Conditionally controll the body scroll lock. Defaults to `true`.
 */
export function useLockBodyScroll(enabled = true) {
  useLayoutEffect(() => {
    if (!enabled) return;

    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;

    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden';

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [enabled]);
}
