import { Box, Button, Text } from '@mentimeter/ragnar-ui';
import { useRouter } from 'next/navigation';
import * as React from 'react';
import { sendResultsMail } from '../../utils/emailSender';
import { resetIdentifier } from '../../utils/identifier';
import { trackCustomFbEvent, trackEvent, useTrack } from '../../utils/tracker';
import { useAppSelector } from '../../redux-hooks';
import { getHasCustomTheme, getIsQfaActive } from '../../selectors';
import { useSplitTrack } from '../../split.io';
import { SeriesLogo } from '../questions/SeriesLogo';
import { useTranslate } from '../localization/Translate';
import { RequestResultsForm } from './RequestResultsForm';
import SignUpButton from './SignUpButton';
import type { FinishScreenProps } from './FinishScreenProps';
import { VotingScreenDefaultLayout } from './FinishScreenExperiment';
import { SendMeResultsButton } from './SendMeResultsButton';

export enum ResultStatus {
  INITIAL = 'INITIAL',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
}

export function FinishScreen({ qfa }: FinishScreenProps) {
  const trackSplit = useSplitTrack();
  const translate = useTranslate();
  const router = useRouter();
  const trackVoter = useTrack();

  React.useEffect(() => {
    trackEvent('event status', 'finished');
  }, []);

  React.useEffect(() => {
    trackSplit('End_screen.Visited');
    trackVoter({
      event: 'Viewed end screen',
    });
  }, [trackSplit, trackVoter]);

  const resultsSharing = useAppSelector(
    (state) => state.series.results_sharing,
  );
  const [requestResultsStatus, setRequestResultsStatus] =
    React.useState<ResultStatus>(ResultStatus.INITIAL);

  const showQfaButton = useAppSelector(getIsQfaActive);
  const voteKey = useAppSelector((state) => state.series.vote_key);
  const hasCustomTheme = useAppSelector(getHasCustomTheme);
  const voteAgainEnabled = useAppSelector(
    (state) => state.series.vote_again_enabled,
  );

  const submitResultsForm = (emailAddress: string) => {
    setRequestResultsStatus(ResultStatus.SUCCESS);
    trackEvent('form', 'submitted', 'Submit results email');
    trackSplit('End_screen.Form_submitted.Request_results');
    trackCustomFbEvent({
      eventName: 'Voter registered email address',
    });
    // For now we just swallow the error
    sendResultsMail({
      emailAddress,
      voteKey,
    }).catch((_e) => {});
  };

  const openResultsForm = () => {
    setRequestResultsStatus(ResultStatus.IN_PROGRESS);

    trackVoter({
      event: 'Clicked get results',
    });
    trackEvent('button', 'clicked', 'Get presentation results');
  };

  const voteAgain = () => {
    resetIdentifier();
    trackSplit('End_screen.Button_clicked.Vote_again_on_same');

    router.push(`/${voteKey}`);
  };

  return (
    <VotingScreenDefaultLayout>
      <SeriesLogo pt="space20" pb="space10" />
      <Box alignItems="center" width="100%">
        <Box width="100%" mb="space12" alignItems="center">
          <Text
            as="h1"
            color="text"
            fontSize="175"
            lineHeight="snug"
            textAlign="center"
            fontWeight="regular"
          >
            {translate('messages.thank_you')}
          </Text>
        </Box>
        <Box alignItems="stretch" gap="space2" width="100%">
          {requestResultsStatus === ResultStatus.IN_PROGRESS ? (
            <RequestResultsForm onSubmit={submitResultsForm} />
          ) : (
            <>
              <SignUpButton hasCustomTheme={hasCustomTheme} variant="primary" />
              {showQfaButton && <Box alignItems="stretch">{qfa}</Box>}
              {requestResultsStatus === ResultStatus.INITIAL &&
                resultsSharing === 'on' && (
                  <SendMeResultsButton onClick={openResultsForm} />
                )}
              {requestResultsStatus === ResultStatus.SUCCESS && (
                <Box mb={2} alignItems="stretch">
                  <Text textAlign="center" color="text" my={2}>
                    {translate('success.check_inbox')}
                  </Text>
                </Box>
              )}
              {voteAgainEnabled && (
                <Button
                  size="large"
                  aria-label={translate('success.participate_again')}
                  onClick={voteAgain}
                  py="space4"
                  px="space8"
                >
                  {translate('success.participate_again')}
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
    </VotingScreenDefaultLayout>
  );
}
