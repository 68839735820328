import {
  Box,
  Label,
  type BoxT,
  Meta,
  TooltipDescriptive,
  CharacterCountWrap,
  CharacterCount,
  type TextInputItemT,
  TextInputItem,
} from '@mentimeter/ragnar-ui';
import { Field, type FieldProps } from 'formik';
import React, { type JSX } from 'react';
import { getErrorMessage, getShouldShowError } from './utils';
import { PrefixWrapper } from './PrefixWrapper';
import { InputFeedback } from './InputFeedback';

export interface TextInputProps extends BoxT {
  name: string;
  label: string;
  prefix?: string;
  optional?: boolean;
  description?: string;
  placeholder?: string | undefined;
  hintText?: React.ReactNode;
  type?: string;
  autoComplete?: string;
  autoFocus?: boolean | undefined;
  disabled?: boolean | undefined;
  maxLength?: number;
  inputBoxProps?: TextInputItemT;
  validate?: (value: any) => void | string | Promise<any>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  innerRef?: any;
}

export function TextInput({
  name,
  label,
  prefix,
  description,
  optional = false,
  placeholder,
  type,
  validate,
  innerRef,
  hintText,
  onBlur,
  onChange,
  onFocus,
  autoComplete,
  autoFocus,
  disabled,
  maxLength,
  inputBoxProps,
  ...boxProps
}: TextInputProps) {
  return (
    <Field name={name} validate={validate} innerRef={innerRef}>
      {(fieldProps: FieldProps) => {
        const shouldShowErrorMessage = getShouldShowError(fieldProps);
        return (
          <Box {...boxProps}>
            <Box flexDirection="row" alignItems="flex-start" mb={2}>
              <Box flexDirection="row">
                <Label htmlFor={name} mb={0}>
                  {label}
                  {optional ? (
                    <Meta fontWeight="regular" lineHeight="heading" ml={1}>
                      (optional)
                    </Meta>
                  ) : null}
                </Label>
              </Box>
              {description ? (
                <TooltipDescriptive
                  description={description}
                  id={`${name}-label-description`}
                />
              ) : null}
            </Box>
            <CharacterCountWrap width="100%">
              <ConditionalPrefixWrapper
                prefix={prefix}
                disabled={disabled}
                shouldShowErrorMessage={shouldShowErrorMessage}
              >
                <TextInputItem
                  className="inputClass"
                  inputSize="compact"
                  {...inputBoxProps}
                  data-testid={`${name}-input`}
                  id={name}
                  aria-describedby={`input-feedback-${name} character-counter-${name}`}
                  value={fieldProps.field.value}
                  name={fieldProps.field.name}
                  autoComplete={autoComplete}
                  autoFocus={autoFocus}
                  disabled={disabled}
                  maxLength={maxLength}
                  onBlur={(e) => {
                    fieldProps.field.onBlur(e);
                    if (onBlur) onBlur(e);
                  }}
                  onChange={(e) => {
                    fieldProps.field.onChange(e);
                    if (onChange) onChange(e);
                  }}
                  onFocus={onFocus}
                  type={type}
                  status={shouldShowErrorMessage ? 'error' : undefined}
                  placeholder={placeholder}
                />
              </ConditionalPrefixWrapper>
              {maxLength !== undefined && (
                <CharacterCount
                  id={`character-counter-${name}`}
                  maxLength={maxLength}
                  value={fieldProps.field.value}
                />
              )}
            </CharacterCountWrap>
            <InputFeedback
              error={getErrorMessage(fieldProps)}
              showError={shouldShowErrorMessage}
              hint={hintText}
              id={`input-feedback-${name}`}
            />
          </Box>
        );
      }}
    </Field>
  );
}

export interface ConditionalPrefixWrapperT {
  prefix: string | undefined;
  disabled: boolean | undefined;
  shouldShowErrorMessage: boolean;
  children: JSX.Element;
}

function ConditionalPrefixWrapper({
  prefix,
  disabled,
  shouldShowErrorMessage,
  children,
}: ConditionalPrefixWrapperT) {
  return prefix ? (
    <PrefixWrapper
      disabled={disabled}
      prefix={prefix}
      status={shouldShowErrorMessage ? 'error' : 'idle'}
    >
      {children}
    </PrefixWrapper>
  ) : (
    children
  );
}
