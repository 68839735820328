import * as React from 'react';
import { Button } from '@mentimeter/ragnar-ui';
import { useRouter } from '@mentimeter/next-navigation';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { useTranslate } from '../localization/Translate';
import { useSplitTrack, useSplitTreatments } from '../../split.io';
import { useSeriesIdByKey } from '../desktop-experience/useSeriesIdByKey';
import { useAppSelector } from '../../redux-hooks';
import { useIntegration } from '../integrations';
import { isTryMode } from '../../utils/isTryMode';

interface SendMeResultsButtonProps {
  onClick: () => void;
}

const MENTIMETER_URL = process.env.NEXT_PUBLIC_WWW_URL || 'mentimeter.com';

export const SendMeResultsButton = ({ onClick }: SendMeResultsButtonProps) => {
  const trackSplit = useSplitTrack();
  const translate = useTranslate();
  const router = useRouter();
  const voteKey = useAppSelector((state) => state.series.vote_key);
  const { integrationId } = useIntegration();
  const tryModeOrIntegrated = isTryMode() || Boolean(integrationId);
  const { OG_Menti_Send_Results_Flow } = useSplitTreatments([
    'OG_Menti_Send_Results_Flow',
  ]);
  const treatment = OG_Menti_Send_Results_Flow?.treatment || 'control';
  const isInExperiment = ['v2', 'v3'].includes(treatment);
  const seriesId = useSeriesIdByKey(voteKey, isInExperiment);
  const buttonTitle = isInExperiment
    ? 'View the results'
    : translate('success.send_me_the_results');

  const clickHandler = React.useCallback(() => {
    if (!seriesId) {
      // no seriesId loaded for some reason, fall back to control behavior
      return onClick();
    }

    if (tryModeOrIntegrated) {
      // experiment should be ignored in integrations
      return onClick();
    }

    const presentationUrl = `/app/presentation/${seriesId}/view?source=request-result`;
    const signupUrl = `/auth/signup?continue=${encodeURIComponent(presentationUrl)}`;
    const utmParams = `&utm_source=govote&utm_medium=button&utm_content=${treatment}`;

    trackSplit('End_screen.Button_clicked.Get_presentation_results');

    switch (treatment) {
      case 'v2':
        router.push(MENTIMETER_URL + signupUrl + utmParams);
        break;
      case 'v3':
        router.push(MENTIMETER_URL + presentationUrl + utmParams);
        break;
      default:
        return onClick();
    }
  }, [seriesId, tryModeOrIntegrated, trackSplit, treatment, onClick, router]);

  if (isInExperiment && !seriesId) {
    return <LoaderIcon />;
  }

  return (
    <Button
      size="large"
      aria-label={buttonTitle}
      onClick={clickHandler}
      py="space4"
      px="space8"
    >
      {buttonTitle}
    </Button>
  );
};
