import debounce from 'lodash/debounce';
import {
  wasHandledNetworkError,
  wasMissingResponseError,
  type ReactionPayload,
} from '@mentimeter/http-clients';
import { addBreadcrumb } from '@mentimeter/errors/sentry';
import { captureException, MentiError } from '@mentimeter/errors/edge';
import { postReaction } from '../../api/series';

const REACTION_VOTE_DEBOUNCE_IN_MS = 1000;

export const submitReactionVote = debounce(
  async (
    questionPublicKey: string,
    payload: ReactionPayload,
    { onDone }: { onDone: () => void },
  ) => {
    try {
      await postReaction(questionPublicKey, payload);
    } catch (err: any) {
      if (wasMissingResponseError(err)) {
        addBreadcrumb({
          message: 'No response on postReaction',
          category: 'network',
          level: 'warning',
          data: { payload },
        });
      } else if (!wasHandledNetworkError(err)) {
        captureException(
          new MentiError('send reaction debounced failed', {
            cause: err,
            feature: 'live',
          }),
        );
      }
    }
    onDone();
  },
  REACTION_VOTE_DEBOUNCE_IN_MS,
  { leading: true },
);
