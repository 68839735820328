import * as React from 'react';
import { Box, Text, Button } from '@mentimeter/ragnar-ui';
import { SeriesLogo } from '../questions/SeriesLogo';
import { PageLayout } from '../../ui';
import { useTranslate } from '../localization/Translate';

interface WelcomeBackProps {
  name: string;
  onContinue: () => void;
}

export const WelcomeBack = ({ name, onContinue }: WelcomeBackProps) => {
  const translate = useTranslate();

  return (
    <PageLayout footer={null}>
      <Box
        flex="1"
        width="100%"
        gap="space2"
        alignItems="center"
        m="auto"
        maxWidth={[null, '600px']}
      >
        <SeriesLogo />
        <Text fontSize="175" width="100%">
          {translate('identified_responses.welcome_back')}
        </Text>
        <Text fontSize="112.5">
          {translate('identified_responses.you_are_already_in_session', {
            name,
          })}
        </Text>
        <Button
          mt="space3"
          type="button"
          variant="primary"
          onClick={onContinue}
        >
          {translate('identified_responses.continue')}
        </Button>
      </Box>
    </PageLayout>
  );
};
