import * as React from 'react';
import type { Question } from '@mentimeter/http-clients';
import { InformationModal } from '../../ui/Components/InformationModal';
import { skipQuestion } from '../../reducers/votingSlice';
import { useAppDispatch } from '../../redux-hooks';
import { useTranslate } from '../localization/Translate';

interface Props {
  question: Question;
}

/*
 *   Modal solution to handle "inline" message of this question is
 *   not open for voting. Handled a bit differently than WaitForOpen
 *   component, as WaitForOpen component has a very special use case
 *   and this component is for a regular not open question
 */
export const WaitForOpenModal = ({ question }: Props) => {
  const dispatch = useAppDispatch();
  const translate = useTranslate();

  function handleSkip() {
    dispatch(skipQuestion());
  }

  const isVotingOpen = question.type === 'slide' || question.active;

  return (
    <InformationModal
      show={!isVotingOpen}
      title={translate('question.not_open')}
    >
      <InformationModal.Description>
        {translate('question.please_wait_open')}
      </InformationModal.Description>

      <InformationModal.ActionContainer>
        <InformationModal.Action size="large" onClick={handleSkip} primary>
          {translate('buttons.next_question')}
        </InformationModal.Action>
      </InformationModal.ActionContainer>
    </InformationModal>
  );
};
