import * as React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { Box, type BoxT } from '../Box';
import { variants } from './Ratio.variants';
import type { RatioVariantProps } from './Ratio.variants';

interface RatioDefault extends BoxT {
  children?: React.ReactNode;
}

export type RatioValue =
  | (RatioVariantProps & { customRatio?: never })
  | {
      customRatio?: string;
      ratio?: never;
    };

export type RatioT = RatioDefault & RatioValue;

export const Ratio = ({
  ratio,
  customRatio,
  children,
  className,
  style,
  ...props
}: RatioT) => {
  const ratioPadddingBottomClass = customRatio
    ? 'before:pb-[var(--ratio-padding-bottom)]'
    : variants({ ratio });
  return (
    <Box
      {...props}
      className={cn(
        [
          'block',
          'w-full',
          'before:block',
          'before:h-0',
          'before:w-0',
          'before:content-[""]',
        ],
        ratioPadddingBottomClass,
        className,
      )}
      style={
        {
          ...style,
          '--ratio-padding-bottom': customRatio,
        } as React.CSSProperties
      }
    >
      <Box className="absolute top-0 right-0 bottom-0 left-0">{children}</Box>
    </Box>
  );
};

export const RATIO_LANDSCAPE = '56.25%';
