import * as React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import type { ClickableT } from '../Clickable';
import { Clickable } from '../Clickable';

export interface ActionT extends ClickableT {
  activeStateStyle?: 'outline' | 'filledIn';
  // States
  active?: boolean | undefined;
  disabled?: boolean | undefined;
  danger?: boolean | undefined;
  hidden?: boolean | undefined;
}

export const Action = React.forwardRef(
  (
    {
      disabled,
      danger,
      active = false,
      activeStateStyle = 'outline',
      extend,
      borderColor,
      hidden,
      className,
      ...props
    }: ActionT,
    ref,
  ) => {
    const styling = [
      'w-full',
      'items-stretch',
      'rounded',
      'border-2',
      'bg',
      'border-solid',
      'select-none',
      'text-left',
      'disabled:pointer-events-none',
      'pointer-events-auto',
      'transition-all ease-in delay-100',
      'disabled:opacity-60',
      'opacity-100',
      'focus:shadow-[0_0_0_3px_rgba(var(--color-primary),0.4)]',
      'active:shadow-[0_0_0_3px_rgba(var(--color-primary),0.4)]',
    ];

    if (activeStateStyle === 'filledIn' && active) {
      styling.push('bg-primary');
    }
    if (hidden) {
      styling.push('border-dashed');
    }

    const useFelaBorderColor = !danger && !active && borderColor;
    const useFelaBorderHoverColor = !danger && !hidden && borderColor;
    const tailwindBorderStyle = [
      danger ? 'border-negative' : active ? 'border-primary' : 'border-default',
      danger
        ? 'hover:border-negative'
        : hidden
          ? 'hover:border-default'
          : 'hover:border-primary',
    ];

    styling.push(...tailwindBorderStyle);

    return (
      <Clickable
        ref={ref}
        {...props}
        disabled={disabled}
        borderColor={useFelaBorderColor ? borderColor : undefined}
        className={cn(styling, className)}
        extend={({ theme }) => ({
          ':hover': {
            borderColor: useFelaBorderHoverColor ? borderColor : undefined,
          },
          ...(extend ? extend({ theme }) : {}),
        })}
      />
    );
  },
);

Action.displayName = 'Action';
