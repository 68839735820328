import * as React from 'react';
import { Fade, Float } from '@mentimeter/ragnar-motion';
import { Box } from '@mentimeter/ragnar-ui';
import { HourGlass } from '@mentimeter/ragnar-visuals';
import { Idle } from '../../ui';
import { useTranslate } from '../localization/Translate';
/*
 * Screen showed when you have voted and waits for the presenter to
 * go to the next question.
 */
const WaitForActive = ({
  pictogramColor,
}: {
  pictogramColor: string | undefined;
}) => {
  const translate = useTranslate();

  return (
    <Idle
      preTitle={translate('messages.please_wait')}
      title={translate('wait_active.please_wait_show')}
    >
      <Fade show>
        <Float duration={2000}>
          <Box height="112px" my="space14">
            <HourGlass userColor={pictogramColor} />
          </Box>
        </Float>
      </Fade>
    </Idle>
  );
};

export default WaitForActive;
