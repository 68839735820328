import { colors } from './colors';
import { visualizationColors } from './visualizationColors';
import { pinkDark600, blackBase, blueDark500, whiteBase } from './palette';
import {
  shade,
  alpha,
  isLight,
  contrast,
  createAccessibleColor,
} from './utils';
import type { UserColorsT, UserDsc } from './createDscFromUserColors.types';

function getContrastColor(fillColor: string, backgroundColor: string): string {
  if (contrast('white', fillColor) > 3) {
    return 'white';
  } else if (contrast(backgroundColor, fillColor) > 3) {
    return backgroundColor;
  }
  return 'black';
}

function getFillTextColors(fillColors: string[], backgroundColor: string) {
  return fillColors.map((fillColor) =>
    getContrastColor(fillColor, backgroundColor),
  );
}

export function createDscFromUserColors(userColors: UserColorsT): UserDsc {
  const fillColor = userColors.bar_color[0] ?? userColors.text_color;

  // if (!fillColor) throw new Error('No fillColors present in user theme');

  const neutral = shade(
    userColors.background_color,
    userColors.text_color,
    0.2,
  );

  const neutralWeak = shade(neutral, userColors.background_color, 0.6);

  const interactive = createAccessibleColor({
    bgColor: userColors.background_color,
    baseColor: fillColor,
    mixColor: userColors.text_color,
    amountOfMixColor: 0,
  });

  return {
    colors: {
      ...colors,
      bg: userColors.background_color,
      bgStrong: isLight(userColors.background_color)
        ? shade(userColors.background_color, blackBase, 0.08)
        : shade(userColors.background_color, whiteBase, 0.08),
      //bgOverlay: doesn't change
      surface: userColors.background_color,
      surfaceHover: shade(userColors.background_color, blackBase, 0.1),
      surfaceActive: shade(userColors.background_color, blackBase, 0.15),
      surfaceChecked: shade(fillColor, userColors.background_color, 0.8),
      surfaceCheckedHover: shade(fillColor, userColors.background_color, 0.65),
      surfaceRaised: userColors.background_color,
      surfaceSunken: shade(userColors.background_color, blackBase, 0.1),
      surfaceOverlay: userColors.background_color,
      highContrast: isLight(userColors.background_color)
        ? blueDark500
        : whiteBase,
      borderStrong: createAccessibleColor({
        bgColor: userColors.background_color,
        baseColor: userColors.background_color,
        mixColor: userColors.text_color,
        amountOfMixColor: 0.3,
        contrastLimit: 3,
      }),
      border: shade(userColors.background_color, userColors.text_color, 0.25),
      borderWeak: shade(
        userColors.background_color,
        userColors.text_color,
        0.2,
      ),
      borderWeaker: shade(
        userColors.background_color,
        userColors.text_color,
        0.15,
      ),
      borderBrandWeakest: shade(fillColor, userColors.background_color, 0.5),
      borderPrimaryWeakest: shade(fillColor, userColors.background_color, 0.5),
      //borderNegativeWeakest: doesn't change
      borderNeutralWeakest: shade(neutral, userColors.background_color, 0.5),
      //borderInfoWeakest: doesn't change
      //borderPositiveWeakest: doesn't change
      //borderNoticeWeakest: doesn't change
      borderSecondaryWeakest: shade(
        fillColor,
        userColors.background_color,
        0.5,
      ),
      borderDisabled: shade(
        userColors.background_color,
        userColors.text_color,
        0.15,
      ),
      brand: fillColor,
      brandWeak: shade(fillColor, userColors.background_color, 0.65),
      brandWeakest: shade(fillColor, userColors.background_color, 0.8),
      text: userColors.text_color,
      textWeak: createAccessibleColor({
        bgColor: userColors.background_color,
        baseColor: userColors.background_color,
        mixColor: userColors.text_color,
        amountOfMixColor: 0.75,
      }),
      textWeaker: createAccessibleColor({
        bgColor: userColors.background_color,
        baseColor: userColors.background_color,
        mixColor: userColors.text_color,
        amountOfMixColor: 0.5,
      }),
      textLink: userColors.text_color,
      textPrimary: userColors.text_color,
      //textNegative: doesn't change
      textDisabled: shade(
        userColors.background_color,
        userColors.text_color,
        0.25,
      ),
      onBrand: getContrastColor(fillColor, userColors.text_color),
      onBrandWeak: createAccessibleColor({
        bgColor: userColors.background_color,
        baseColor: fillColor,
        mixColor: userColors.text_color,
        amountOfMixColor: 0.7,
      }),
      onBrandWeakest: createAccessibleColor({
        bgColor: userColors.background_color,
        baseColor: fillColor,
        mixColor: userColors.text_color,
        amountOfMixColor: 0.7,
      }),
      onDisabled: shade(
        userColors.background_color,
        userColors.text_color,
        0.25,
      ),
      onDisabledStrong: shade(
        userColors.background_color,
        userColors.text_color,
        0.35,
      ),
      //onInfo: doesn't change
      //onInfoWeak: doesn't change
      //onInfoWeakest: doesn't change
      onHighContrast: isLight(userColors.background_color)
        ? whiteBase
        : blackBase,
      onInteractive: isLight(interactive) ? blackBase : whiteBase,
      onNeutral: getContrastColor(neutral, userColors.text_color),
      onNeutralWeak: createAccessibleColor({
        bgColor: userColors.background_color,
        baseColor: neutral,
        mixColor: userColors.text_color,
        amountOfMixColor: 0.7,
      }),
      onNeutralWeakest: createAccessibleColor({
        bgColor: userColors.background_color,
        baseColor: neutral,
        mixColor: userColors.text_color,
        amountOfMixColor: 0.7,
      }),
      //onNegative: doesn't change
      //onNegativeWeak: doesn't change
      //onNegativeWeakest: doesn't change
      //onNotice: doesn't change
      //onNoticeWeak: doesn't change
      //onNoticeWeakest: doesn't change
      //onPositive: doesn't change
      //onPositiveWeak: doesn't change
      //onPositiveWeakest: doesn't change
      onPrimary: getContrastColor(fillColor, userColors.text_color),
      onPrimaryWeak: createAccessibleColor({
        bgColor: userColors.background_color,
        baseColor: fillColor,
        mixColor: userColors.text_color,
        amountOfMixColor: 0.7,
      }),
      onPrimaryWeakest: createAccessibleColor({
        bgColor: userColors.background_color,
        baseColor: fillColor,
        mixColor: userColors.text_color,
        amountOfMixColor: 0.7,
      }),
      onSecondary: getContrastColor(fillColor, userColors.text_color),
      onSecondaryWeak: createAccessibleColor({
        bgColor: userColors.background_color,
        baseColor: fillColor,
        mixColor: userColors.text_color,
        amountOfMixColor: 0.7,
      }),
      onSecondaryWeakest: createAccessibleColor({
        bgColor: userColors.background_color,
        baseColor: fillColor,
        mixColor: userColors.text_color,
        amountOfMixColor: 0.7,
      }),
      primary: fillColor,
      primaryWeak: shade(fillColor, userColors.background_color, 0.65),
      primaryWeakest: shade(fillColor, userColors.background_color, 0.8),
      //info: doesn't change
      //infoWeak: doesn't change
      //infoWeakest: doesn't change
      neutral,
      neutralWeak,
      neutralWeakest: shade(neutral, userColors.background_color, 0.85),
      //negative: doesn't change
      //negativeWeak: doesn't change
      negativeWeakest: alpha(pinkDark600, 0.08),
      //notice: doesn't change
      //noticeWeak: doesn't change
      //noticeWeakest: doesn't change
      //positive: doesn't change
      //positiveWeak: doesn't change
      //positiveWeakest: doesn't change
      secondary: fillColor,
      secondaryWeak: shade(fillColor, userColors.background_color, 0.65),
      secondaryWeakest: shade(fillColor, userColors.background_color, 0.8),
      disabled: isLight(userColors.background_color)
        ? shade(userColors.background_color, blackBase, 0.03)
        : shade(userColors.background_color, whiteBase, 0.03),
      disabledStrong: isLight(userColors.background_color)
        ? shade(userColors.background_color, blackBase, 0.09)
        : shade(userColors.background_color, whiteBase, 0.09),
      interactive,
      interactiveFocused: alpha(fillColor, 0.2),
      // COMPONENT TOKENS
      buttonBrandBg: fillColor,
      buttonBrandBgHover: shade(fillColor, blackBase, 0.1),
      buttonBrandBgActive: shade(fillColor, blackBase, 0.2),
      //buttonNegativeBg: doesn't change
      //buttonNegativeBgHover: doesn't change
      //buttonNegativeBgActive: doesn't change
      //buttonNegativeWeakBg: doesn't change
      //buttonNegativeWeakBgHover: doesn't change
      //buttonNegativeWeakBgActive: doesn't change
      buttonOutlineBgHover: alpha(fillColor, 0.1),
      buttonOutlineBgActive: alpha(fillColor, 0.2),
      buttonOutlineBgChecked: fillColor,
      buttonOutlineBgCheckedHover: alpha(fillColor, 0.1),
      buttonOutlineBgCheckedActive: alpha(fillColor, 0.2),
      //buttonPositiveBg: doesn't change
      //buttonPositiveBgHover: doesn't change
      //buttonPositiveBgActive: doesn't change
      buttonPrimaryBg: fillColor,
      buttonPrimaryBgHover: shade(fillColor, blackBase, 0.1),
      buttonPrimaryBgActive: shade(fillColor, blackBase, 0.2),
      buttonPrimaryBgChecked: shade(
        fillColor,
        getContrastColor(fillColor, userColors.background_color),
        0.3,
      ),
      buttonPrimaryBgCheckedHover: shade(
        fillColor,
        getContrastColor(fillColor, userColors.background_color),
        0.2,
      ),
      buttonPrimaryBgCheckedActive: shade(
        fillColor,
        getContrastColor(fillColor, userColors.background_color),
        0.1,
      ),
      buttonSecondaryBgHover: alpha(fillColor, 0.25),
      buttonSecondaryBgActive: alpha(fillColor, 0.4),
      buttonSecondaryBgChecked: shade(
        fillColor,
        getContrastColor(fillColor, userColors.background_color),
        0.3,
      ),
      buttonSecondaryBgCheckedHover: shade(
        fillColor,
        getContrastColor(fillColor, userColors.background_color),
        0.2,
      ),
      buttonSecondaryBgCheckedActive: shade(
        fillColor,
        getContrastColor(fillColor, userColors.background_color),
        0.1,
      ),
      buttonTertiaryBg: neutralWeak,
      buttonTertiaryBgHover: shade(
        neutral,
        getContrastColor(neutral, userColors.background_color),
        0.1,
      ),
      buttonTertiaryBgActive: shade(
        neutral,
        getContrastColor(neutral, userColors.background_color),
        0.2,
      ),
      buttonTertiaryBgChecked: shade(
        neutral,
        getContrastColor(neutral, userColors.background_color),
        0.3,
      ),
      buttonTertiaryBgCheckedHover: shade(
        neutral,
        getContrastColor(neutral, userColors.background_color),
        0.2,
      ),
      buttonTertiaryBgCheckedActive: shade(
        neutral,
        getContrastColor(neutral, userColors.background_color),
        0.1,
      ),
      buttonPrimaryReverseBgHover: shade(
        neutral,
        getContrastColor(
          neutral,
          getContrastColor(neutral, userColors.background_color),
        ),
        0.1,
      ),
      buttonPrimaryReverseBg: userColors.background_color,
      buttonPrimaryReverseBgActive: shade(
        neutral,
        getContrastColor(neutral, userColors.background_color),
        0.2,
      ),
      buttonPrimaryReverseBgChecked: shade(
        fillColor,
        getContrastColor(fillColor, userColors.background_color),
        0.3,
      ),
      buttonPrimaryReverseBgCheckedHover: shade(
        fillColor,
        getContrastColor(fillColor, userColors.background_color),
        0.2,
      ),
      buttonPrimaryReverseBgCheckedActive: shade(
        fillColor,
        getContrastColor(fillColor, userColors.background_color),
        0.1,
      ),

      inputBg: userColors.background_color,
      inputBgActive: userColors.background_color,
    },
    visualizationColors: {
      ...visualizationColors,
      backgroundColor: userColors.background_color,
      textColor: userColors.text_color,
      lineColor: userColors.line_color,
      fillColors: userColors.bar_color,
      fillTextColors: getFillTextColors(
        userColors.bar_color,
        userColors.background_color,
      ),
    },
  };
}
