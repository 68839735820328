import React from 'react';
import { Svg } from '@mentimeter/ragnar-visuals';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../Box';
import { variants } from './Avatar.variants';
import type { AvatarT } from './Avatar.types';

export const AvatarDashed = ({
  icon,
  badge,
  disabled = false,
  bg,
  fillColor,
  size,
  className,
  ...rest
}: AvatarT) => {
  const variantClasses = variants({ size });
  return (
    <Box
      className={cn(
        variantClasses,
        'flex',
        'relative',
        'items-center',
        'justify-center',
        badge && 'top-[5px]',
        className,
      )}
      {...rest}
    >
      <div className="absolute w-full h-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <Svg viewBox="0 0 100 100">
          <circle
            cx="50"
            cy="50"
            r="48"
            strokeWidth="3"
            strokeDasharray="12, 11"
            className={cn(
              fillColor
                ? 'fill-[theme(backgroundColor.DEFAULT)]'
                : 'fill-transparent',
              disabled
                ? 'stroke-[theme(borderColor.strong)]'
                : 'stroke-[theme(textColor.weak)]',
            )}
          />
        </Svg>
      </div>

      <Box>{icon}</Box>
    </Box>
  );
};
