import * as React from 'react';
import { SocketIoConnectionProvider } from '@mentimeter/quiz';
import { getRegionByVoteKey } from '@mentimeter/region';
import { useAppSelector } from '../../redux-hooks';
import {
  usePresentationState,
  usePresentationSubscription,
} from '../../presentation-state';
import { PresenterPaceQuestion as DesktopExperiencePresenterPaceQuestion } from '../desktop-experience/PresenterPaceQuestion';
import { PrefetchQuestionModules } from '../questions/PrefetchQuestionModules';
import { useSeriesIdByKey } from '../desktop-experience/useSeriesIdByKey';
import { PresenterPaceQuestion } from './PresenterPaceQuestion';

const apis = {
  regions: {
    eu: {
      quizUrl: process.env.NEXT_PUBLIC_EU_QUIZ_URL as string,
      blabUrl: process.env.NEXT_PUBLIC_EU_TRACKING_URL as string,
    },
    us: {
      quizUrl: process.env.NEXT_PUBLIC_US_QUIZ_URL as string,
      blabUrl: process.env.NEXT_PUBLIC_US_TRACKING_URL as string,
    },
  },
};

export const PresenterPace = ({
  shouldRequestDesktopExperience,
}: {
  shouldRequestDesktopExperience: boolean;
}) => {
  const voteKey = useAppSelector((state) => state.series.vote_key);
  const seriesId = useSeriesIdByKey(voteKey, shouldRequestDesktopExperience);

  usePresentationSubscription(voteKey);

  // Question
  const { hasVotedOnCurrentQuestion, waitForNextSlide, question } =
    usePresentationState();

  // Quiz
  const quizUrl = React.useMemo(() => {
    return apis.regions[getRegionByVoteKey(voteKey)].quizUrl;
  }, [voteKey]);

  const isQfaPanelOpen = useAppSelector((state) => state.qfa.isQfaPanelOpen);
  React.useEffect(() => {
    if (isQfaPanelOpen) return;
    // Scroll to top whenever the questions changes
    // or we transition to one of the waiting states.
    window.scrollTo(0, 0);
  }, [
    question.id,
    hasVotedOnCurrentQuestion,
    waitForNextSlide,
    isQfaPanelOpen,
  ]);

  return (
    <SocketIoConnectionProvider url={quizUrl} enabled>
      <PrefetchQuestionModules />

      {seriesId ? (
        <DesktopExperiencePresenterPaceQuestion
          question={question}
          seriesId={seriesId}
        />
      ) : (
        <PresenterPaceQuestion question={question} />
      )}
    </SocketIoConnectionProvider>
  );
};
