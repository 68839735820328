import * as React from 'react';
import {
  Button,
  ErrorMessage,
  Form,
  Box,
  TextInputItem,
} from '@mentimeter/ragnar-ui';
import { useTranslate } from '../localization/Translate';

export interface Props {
  onSubmit: (emailAddress: string) => void;
}

export enum Status {
  INITIAL,
  ERROR,
}

export function RequestResultsForm({ onSubmit }: Props) {
  const translate = useTranslate();
  const [status, setStatus] = React.useState<Status>(Status.INITIAL);

  const handleSubmit = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    // Get the value of the first (and only) input field of the form
    // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const emailInputValue = event.target[0].value;

    if (isValidEmail(emailInputValue)) {
      onSubmit(emailInputValue);
    } else {
      setStatus(Status.ERROR);
    }
  };

  return (
    <Form onSubmit={handleSubmit} width="100%" alignItems="stretch">
      <Box mb="space4">
        <TextInputItem
          id="results-form-input"
          name="question"
          autoComplete="off"
          maxLength={200}
          placeholder={translate('success.enter_email')}
          aria-describedby={
            status === Status.ERROR ? 'error-msg-invalid-email' : undefined
          }
        />

        {status === Status.ERROR && (
          <ErrorMessage id="error-msg-invalid-email" textAlign="center">
            {translate('success.valid_email')}
          </ErrorMessage>
        )}
      </Box>

      <Button size="large" variant="primary" type="submit">
        {translate('success.email_results')}
      </Button>
    </Form>
  );
}

export const isValidEmail = (email: string) => {
  // Regex taken from https://emailregex.com
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
