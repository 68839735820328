import {
  blue200,
  blue500,
  blue800,
  green200,
  green500,
  green800,
  pinkDark500,
  pinkDark700,
  pinkLight500,
  purple200,
  purple500,
  purpleDark600,
  yellow200,
  yellow500,
  yellowDark500,
} from '@mentimeter/ragnar-colors';
import type { PictogramVariants } from './types';

export const COLOR_MAP: PictogramVariants = {
  blue: {
    pictogramPrimary: blue500,
    pictogramSecondary: blue800,
    pictogramTertiary: blue200,
  },
  green: {
    pictogramPrimary: green500,
    pictogramSecondary: green800,
    pictogramTertiary: green200,
  },
  purple: {
    pictogramPrimary: purple500,
    pictogramSecondary: purpleDark600,
    pictogramTertiary: purple200,
  },
  red: {
    pictogramPrimary: pinkDark500,
    pictogramSecondary: pinkDark700,
    pictogramTertiary: pinkLight500,
  },
  yellow: {
    pictogramPrimary: yellow500,
    pictogramSecondary: yellowDark500,
    pictogramTertiary: yellow200,
  },
};
