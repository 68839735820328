import type { ColorsEnum } from '@mentimeter/ragnar-colors';
import { Box, Text } from '@mentimeter/ragnar-ui';
import { addUnit, capitalize } from '@mentimeter/ragnar-utils';
import React from 'react';

export interface InputFeedbackT {
  id: string;
  error?: string | undefined;
  hint?: React.ReactNode;
  showError: boolean;
  alwaysTakeSpace?: boolean;
  capitalized?: boolean;
}

export function InputFeedback({
  id,
  error,
  hint,
  showError,
  alwaysTakeSpace = true,
  capitalized = false,
}: InputFeedbackT) {
  let msg: React.ReactNode = alwaysTakeSpace ? '\u00A0' : null;
  let color: ColorsEnum = 'textWeaker';

  if (showError) {
    msg = error;
    color = 'negative';
  } else if (hint) {
    msg = hint;
  }
  if (capitalized && typeof msg === 'string') {
    msg = capitalize(msg);
  }
  const msgOrDoesntTakeSpace = msg || !alwaysTakeSpace;
  return (
    <Box
      height={msgOrDoesntTakeSpace ? 'auto' : '24px'}
      extend={({ theme }) => ({
        height: msgOrDoesntTakeSpace
          ? 'auto'
          : addUnit(theme.kosmosLineHeight.fixed[175]),
      })}
    >
      <Text id={id} lineHeight="175" fontSize="87.5" color={color}>
        {msg}
      </Text>
    </Box>
  );
}
