import * as React from 'react';
import { Clickable, Text } from '@mentimeter/ragnar-ui';

export interface CommentPillProps {
  onClick: (arg0: string) => void;
  label: string;
}
export const CommentPill = ({ onClick, label }: CommentPillProps) => {
  return (
    <Clickable
      key={label}
      type="button"
      onClick={() => {
        onClick(label);
      }}
      borderStyle="solid"
      borderColor="primary"
      borderWidth="1px"
      borderRadius="full"
      px="space4"
      py="space2"
    >
      <Text fontSize="100" lineHeight="relaxed" color="textWeak" truncate>
        {label}
      </Text>
    </Clickable>
  );
};
