import * as React from 'react';
import { createPopper } from '@popperjs/core';
import type { ColorsEnum } from '@mentimeter/ragnar-dsc';
import type {
  Modifier,
  Instance,
  VirtualElement,
  PositioningStrategy,
} from '@popperjs/core';
import type { PreventOverflowModifier } from '@popperjs/core/lib/modifiers/preventOverflow';
import type { OffsetModifier } from '@popperjs/core/lib/modifiers/offset';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { PlacementWrap } from './PlacementWrap';

export type PlacementT =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'top-start'
  | 'right-start'
  | 'bottom-start'
  | 'left-start'
  | 'top-end'
  | 'right-end'
  | 'bottom-end'
  | 'left-end';

// useLayoutEffect makes popper perform better when rendered in the browser but can cause issues with server side rendering
// so this uses useLayoutEffect for client side rendering and useEffect for server side rendering
const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export interface PlacementProps {
  children: React.ReactNode;
  referenceId: string | VirtualElement;
  placement?: PlacementT | undefined;
  bg?: ColorsEnum;
  ignorePointer?: boolean;
  showArrow?: boolean;
  autoFocus?: boolean | undefined;
  sameWidth?: boolean | undefined;
  popperStrategy?: PositioningStrategy | undefined;
}

// To prevent placements being cut off by a container
const DEFAULT_MODIFIERS = [
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      mainAxis: true,
      padding: 10,
      rootBoundary: 'viewport',
    },
  } as PreventOverflowModifier,
  {
    name: 'offset',
    enabled: true,
    options: {
      offset: ({ placement }) => {
        const isStartOffset = [
          'bottom-start',
          'top-start',
          'left-start',
          'right-start',
        ].includes(placement);
        const isEndOffset = [
          'bottom-end',
          'top-end',
          'left-end',
          'right-end',
        ].includes(placement);

        if (isStartOffset) {
          return [-10, 6];
        }
        if (isEndOffset) {
          return [10, 6];
        } else {
          return [0, 6];
        }
      },
    },
  } as OffsetModifier,
];

const SAME_WIDTH_MODIFIER: Modifier<'sameWidth', any> = {
  name: 'sameWidth',
  enabled: true,
  fn: ({ state }) => {
    if (state.styles['popper']) {
      state.styles['popper'].width = `${state.rects.reference.width}px`;
    }
  },
  phase: 'beforeWrite',
  requires: ['computeStyles'],
};

const Placement = ({
  placement = 'top',
  referenceId,
  showArrow = true,
  autoFocus = false,
  sameWidth = false,
  children,
  ignorePointer = false,
  bg,
  popperStrategy,
}: PlacementProps) => {
  const [reference, setReference] = React.useState<
    HTMLElement | VirtualElement | null
  >(null);
  const popper = React.useRef<Instance | null>(null);
  const element = React.useRef<HTMLDivElement | null>(null);

  React.useLayoutEffect(() => {
    // check whether referenceId is id or virtualElement
    if (typeof referenceId === 'string') {
      setReference(document.getElementById(referenceId));
    } else if (referenceId) {
      setReference(referenceId);
    }
  }, [referenceId]);

  const create = React.useCallback(() => {
    const modifiers: Modifier<any, any>[] = [...DEFAULT_MODIFIERS];

    if (sameWidth) {
      modifiers.push(SAME_WIDTH_MODIFIER);
    }

    const options: Parameters<typeof createPopper>['2'] = {
      placement,
      modifiers,
      strategy: popperStrategy || 'absolute',
    };

    if (reference && element.current !== null) {
      popper.current = createPopper(reference, element.current, options);
      if (autoFocus === true) {
        element.current.focus();
      }
    }
  }, [reference, autoFocus, placement, sameWidth, popperStrategy]);

  useIsomorphicLayoutEffect(() => {
    create();
    return () => {
      if (popper.current) {
        popper.current.destroy();
      }
    };
  }, [create]);

  // dont render anything until we have an reference to
  // calculate the position from.
  // `refernce` will be null at first render cycle
  if (reference === null) {
    return null;
  }

  // 'fixed' prevents page from jumping when the Placement contains an element with autoFocus set to true
  const defaultClasses = ['fixed'];

  return (
    <PlacementWrap
      ref={element}
      bg={bg}
      ignorePointer={ignorePointer}
      className={cn(defaultClasses)}
    >
      {children}
      {showArrow && <div className="popper--arrow" data-popper-arrow />}
    </PlacementWrap>
  );
};

export { Placement };
