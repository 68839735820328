import React, { useId } from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import type { ColorsEnum } from '@mentimeter/ragnar-colors';
import { Box } from '../Box/Box';
import { Tooltip } from '../Tooltip/Tooltip';
import { BADGE_MAP } from './badgeMap';
import type { BadgeT } from './badge.types';
import { variants } from './Badge.variants';
import { BadgeText, BadgeIconContainer } from './components';
import { badgeLabel } from './Badge.utils';

export const Badge = ({
  type,
  compact,
  description,
  text,
  color,
  textTransform,
  className,
  ...props
}: BadgeT) => {
  const referenceId = useId();
  const { title: TitleFromMap, description: descriptionFromMap } =
    BADGE_MAP[type];

  const tooltipDescription = description ?? descriptionFromMap;
  const classes = variants({ variant: type });
  const size = compact ? 'compact' : 'default';

  return (
    <>
      <Box
        id={referenceId}
        aria-label={badgeLabel(type, text)}
        className={cn(classes, className)}
        {...props}
      >
        {typeof TitleFromMap === 'string' && (
          <BadgeText
            size={size}
            textTransform={textTransform}
            {...(color && { color })}
          >
            {type === 'text' && text ? text : TitleFromMap || 'Unknown'}
          </BadgeText>
        )}
        {typeof TitleFromMap === 'function' && (
          <BadgeIconContainer size={size}>
            <TitleFromMap
              size={compact ? 0 : 1}
              color="inherit"
              {...(color && { color: color as ColorsEnum })}
            />
          </BadgeIconContainer>
        )}
      </Box>
      {tooltipDescription && (
        <Tooltip
          placement="top"
          referenceId={referenceId}
          description={tooltipDescription}
        />
      )}
    </>
  );
};
