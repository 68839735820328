import { useRouter } from 'next/navigation';
import React from 'react';
import { useAudiencePaceVoteParams } from './useAudiencePaceVoteParams';

const getQuestionIndexPath = (voteKey: string, questionIndex: number) =>
  `/${voteKey}/${questionIndex}`;

export const useNavigateToNextSlide = () => {
  const { voteKey, questionIndex } = useAudiencePaceVoteParams();
  const router = useRouter();

  return React.useCallback(() => {
    router.push(getQuestionIndexPath(voteKey, questionIndex + 1));
  }, [router, questionIndex, voteKey]);
};
